import Vue from "vue"
  import DatePicker from 'vue2-datepicker'
  import SortedTablePlugin from "vue-sorted-table"
  import Paginate from "@/components/widgets/pagination.vue";

  Vue.use(SortedTablePlugin, {
    ascIcon: '<i class="mdi mdi-arrow-down"></i>',
    descIcon: '<i class="mdi mdi-arrow-up"></i>'
  })
  export default {
    components: {
      DatePicker,
      Paginate
    },
    data: function() {
      return {
        busy: false, 
        advanced: false,
        status: null,
        totalItem: 0,
        apiStatus: 'ไม่พบข้อมูล',
        doc: {
          start: null,
          end: null
        },
        paiddate: {
          start: null,
          end: null
        },
        perPage: 6,
        sortBy: 'name',
        currentPage: 1,
        currentData: {},
        sortDesc: false,
        fields: [
          { 
            key: 'purchasedOn', 
            label: 'วันที่เอกสาร',
            thClass: 'font-weight-bold text-xs-1/2',
            tdClass: 'td-top text-xs-1/2',
            sortable: true 
          },
          { 
            key: 'doc', 
            label: 'เลขที่เอกสาร',
            thClass: 'font-weight-bold text-xs-1/2',
            tdClass: 'text-gray-400 td-top text-xs-1/2',
            sortable: true 
          },
          { 
            key: 'inv_doc', 
            label: 'เลขที่ใบกำกับภาษี',
            thClass: 'font-weight-bold text-xs-1/2',
            tdClass: 'text-gray-400 td-top text-xs-1/2',
            sortable: true 
          },
          { 
            key: 'bill_doc', 
            label: 'เลขที่ใบวางบิล',
            thClass: 'font-weight-bold text-xs-1/2',
            tdClass: 'text-gray-400 td-top text-xs-1/2',
            sortable: true 
          },
          { 
            key: 'paidDate', 
            label: 'วันที่ชำระ',
            thClass: 'font-weight-bold text-xs-1/2',
            tdClass: 'text-gray-400 td-top text-xs-1/2',
            sortable: true 
          },
          { 
            key: 'debtorCode', 
            label: 'รหัสลูกหนี้',
            thClass: 'font-weight-bold text-xs-1/2',
            tdClass: 'text-gray-400 td-top text-xs-1/2',
            sortable: true 
          },
          { 
            key: 'debtorName', 
            label: 'ชื่อลูกหนี้',
            thClass: 'font-weight-bold text-xs-1/2',
            tdClass: 'text-gray-400 td-top text-xs-1/2',
            sortable: true 
          },
          { 
            key: 'nettotal', 
            label: 'ยอดรวมสุทธิ',
            thClass: 'font-weight-bold text-xs-1/2',
            tdClass: 'text-gray-400 td-top text-xs-1/2',
            sortable: true, 
            class: 'text-right' 
          },
          { 
            key: 'debt', 
            label: 'ยอดค้างชำระ',
            thClass: 'font-weight-bold text-xs-1/2',
            tdClass: 'text-gray-400 td-top text-xs-1/2',
            sortable: true, 
            class: 'text-right' 
          },
          { 
            key: 'paid', 
            label: 'ยอดชำระ',
            thClass: 'font-weight-bold text-xs-1/2',
            tdClass: 'text-gray-400 td-top text-xs-1/2',
            sortable: true, 
            class: 'text-right' 
          },
          { 
            key: 'action', 
            label: '#', 
            thClass: 'font-weight-bold text-xs-1/2',
            tdClass: 'text-gray-400 td-top text-xs-1/2',
            class: 'text-right' 
          }
        ],
        items: []
      }
    },
    computed: {
      rows() {
        return this.items.length
      }
    },
    methods: {
      onInfo(id) { },
      oncreate() { },
      onInitData() {
        try {
          const item = this.items
          this.items = []
          this.busy = true
          this.apiStatus = 'กำลังค้นหาข้อมูล..'
          
          setTimeout(() => {
            this.busy = false
            this.apiStatus = 'ไม่พบข้อมูล'
            
            this.items = item
          }, 1000)
        } catch (error) {
          this.busy = false
          this.apiStatus = 'ไม่พบข้อมูล'
          this.onExceptionHandler(error.response.data.message);
        }
      }
    }
  }